export type SemVerStatus = "ahead" | "behind" | "equal" | undefined

export function semVerStatus({
  version,
  minVersion,
}: {
  version: string
  minVersion: string
}): SemVerStatus {
  const versionParts = version.split(".")
  const minVersionParts = minVersion.split(".")
  if (versionParts.length !== 3 || minVersionParts.length !== 3) {
    // we ignore non 3 part version strings
    return undefined
  } else {
    // compare sem ver parts
    let semanticVersionState: SemVerStatus
    // MAJOR
    if (Number(versionParts[0]) > Number(minVersionParts[0])) {
      semanticVersionState = "ahead"
    } else if (Number(versionParts[0]) < Number(minVersionParts[0])) {
      semanticVersionState = "behind"
    }
    // MINOR
    if (!semanticVersionState) {
      if (Number(versionParts[1]) > Number(minVersionParts[1])) {
        semanticVersionState = "ahead"
      } else if (Number(versionParts[1]) < Number(minVersionParts[1])) {
        semanticVersionState = "behind"
      }
    }
    // PATCH
    if (!semanticVersionState) {
      if (Number(versionParts[2]) > Number(minVersionParts[2])) {
        semanticVersionState = "ahead"
      } else if (Number(versionParts[2]) < Number(minVersionParts[2])) {
        semanticVersionState = "behind"
      } else {
        semanticVersionState = "equal"
      }
    }
    return semanticVersionState
  }
}
