export function useDevice() {
  var isIphoneOrIpad =
    navigator.userAgent.match(/iPhone/i)?.length ||
    navigator.userAgent.match(/iPad/i)?.length
  var isSafari =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    navigator.userAgent.indexOf("CriOS/") === -1
  return {
    isIphoneOrIpad: !!isIphoneOrIpad,
    isSafari,
  }
}
