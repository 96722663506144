import { useState, useEffect, useCallback } from "react"
import { getStorage, ref, getDownloadURL } from "firebase/storage"

import { firebaseHostPath } from "../constants"
import {
  AppConfig,
  AppLinksJSON,
  AppVersion,
  ReleaseConfig,
} from "../app.types"
import { semVerStatus } from "../utils"
import { useDevice } from "../hooks"

import { Version } from "."

type VersionHistoryState = {
  status: "loading" | "loaded" | "error"
  appVersions: AppVersion[]
  selectedVersion: string | null
}

async function getFirebaseStoragePath(filePath: string) {
  const storage = getStorage()
  const fileRef = ref(storage, `${decodeURIComponent(filePath)}`)
  return await getDownloadURL(fileRef)
}

export function VersionHistory({
  title,
  env,
  deploymentDirectory,
  authType,
}: AppConfig) {
  const { isIphoneOrIpad } = useDevice()

  const [state, setState] = useState<VersionHistoryState>({
    status: "loading",
    appVersions: [],
    selectedVersion: null,
  })

  const [selectedVersionReleaseConfig, setSelectedVersionReleaseConfig] =
    useState<ReleaseConfig | null>(null)

  const fetchAppVersions = useCallback(async () => {
    try {
      const appVersionsFilePath =
        authType === "firebase"
          ? await getFirebaseStoragePath(
              `${deploymentDirectory}%2FappVersions.json`
            )
          : `${firebaseHostPath}${deploymentDirectory}%2FappVersions.json?alt=media`
      const appVersionsJSON = await fetch(appVersionsFilePath)
      const appVersionsFile: AppLinksJSON = (await appVersionsJSON.json()) || {}
      let sortedAppVersions: AppVersion[] = []
      if (appVersionsFile.appVersions) {
        sortedAppVersions = appVersionsFile.appVersions.sort((a, b) => {
          const status = semVerStatus({
            version: a.version || "",
            minVersion: b.version || "",
          })
          if (status === "ahead") {
            return -1
          } else if (status === "behind") {
            return 1
          }
          return 0
        })
      }
      setState((currentState) => ({
        ...currentState,
        status: "loaded",
        appVersions: sortedAppVersions,
      }))
    } catch (error) {
      console.log(error)
      setState((currentState) => ({
        ...currentState,
        status: "error",
      }))
    }
  }, [deploymentDirectory, authType])

  const getSelectedVersionReleaseConfig = useCallback(async () => {
    if (!state.selectedVersion) {
      return
    }
    const _androidMetaDataFilePath = `${deploymentDirectory}%2F${state.selectedVersion}%2Foutput-metadata.json`
    const _androidApkPath =
      env === "default"
        ? `${deploymentDirectory}%2F${state.selectedVersion}%2Fapp-release.apk`
        : `${deploymentDirectory}%2F${state.selectedVersion}%2Fapp-${env}-release.apk`
    const _iOSInfoFilePath = `${deploymentDirectory}%2F${state.selectedVersion}%2Finfo.json`
    const _iOSManifestPath = `${deploymentDirectory}%2F${state.selectedVersion}%2Fmanifest.plist`

    if (authType === "firebase") {
      if (isIphoneOrIpad) {
        setSelectedVersionReleaseConfig({
          androidMetaDataFilePath: "",
          androidApkPath: "",
          iOSInfoFilePath: await getFirebaseStoragePath(_iOSInfoFilePath),
          iOSManifestPath: `${firebaseHostPath}${_iOSManifestPath}?alt=media`,
        })
      } else {
        setSelectedVersionReleaseConfig({
          androidMetaDataFilePath: await getFirebaseStoragePath(
            _androidMetaDataFilePath
          ),
          androidApkPath: await getFirebaseStoragePath(_androidApkPath),
          iOSInfoFilePath: "",
          iOSManifestPath: "",
        })
      }
    } else {
      setSelectedVersionReleaseConfig({
        androidMetaDataFilePath: `${firebaseHostPath}${_androidMetaDataFilePath}?alt=media`,
        androidApkPath: `${firebaseHostPath}${_androidApkPath}?alt=media`,
        iOSInfoFilePath: `${firebaseHostPath}${_iOSInfoFilePath}?alt=media`,
        iOSManifestPath: `${firebaseHostPath}${_iOSManifestPath}?alt=media`,
      })
    }
  }, [
    deploymentDirectory,
    env,
    state.selectedVersion,
    authType,
    isIphoneOrIpad,
  ])

  useEffect(() => {
    getSelectedVersionReleaseConfig()
  }, [getSelectedVersionReleaseConfig])

  useEffect(() => {
    fetchAppVersions()
  }, [fetchAppVersions])

  return (
    <div id="content" style={{ paddingBottom: 20 }}>
      <h2 style={{ color: "black" }}>{title}</h2>
      {state.status === "loading" ? (
        <p>Loading app links...</p>
      ) : state.status === "error" ? (
        <p>
          Error loading app links - contact as at{" "}
          <a href="mailto:support@72days.dev">support@72days.dev</a> for help
        </p>
      ) : state.selectedVersion ? (
        <>
          <button
            className="button"
            onClick={() => {
              setState((currentState) => ({
                ...currentState,
                selectedVersion: null,
              }))
            }}
          >
            Back To Version List
          </button>
          <br />
          {selectedVersionReleaseConfig && (
            <Version {...selectedVersionReleaseConfig} />
          )}
        </>
      ) : (
        <>
          {state.appVersions.length ? (
            <ul style={{ marginLeft: 20 }}>
              {state.appVersions.map((appVersion) => {
                const date = appVersion.updated
                  ? new Date(appVersion.updated)
                  : ""
                const dateDisplay = date ? date.toDateString() : ""
                if (appVersion.version) {
                  return (
                    <li key={appVersion.version} style={{ marginBottom: 10 }}>
                      <b>{appVersion.version}</b> {dateDisplay}
                      <br />
                      {JSON.stringify(appVersion.platforms)}
                      <button
                        style={{ marginLeft: 20 }}
                        className="button"
                        onClick={() => {
                          setState((currentState) => ({
                            ...currentState,
                            selectedVersion: appVersion.version || null,
                          }))
                        }}
                      >
                        Get App
                      </button>
                    </li>
                  )
                } else {
                  return null
                }
              })}
            </ul>
          ) : (
            <p>No app links available</p>
          )}
        </>
      )}
    </div>
  )
}
