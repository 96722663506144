import { useState, useEffect, useCallback } from "react"

import { ReleaseConfig } from "../app.types"
import { getUdidURL } from "../constants"
import { useDevice } from "../hooks"

type State = {
  initialised: boolean
  isIphoneOrIpad: null | boolean
  isSafari: null | boolean
  iOSVersion: null | string
  iOSManifestPath: null | string
  androidVersion: null | string
  androidApkPath: null | string
}

export function Version({
  androidMetaDataFilePath,
  iOSInfoFilePath,
  iOSManifestPath,
  androidApkPath,
}: ReleaseConfig) {
  const [state, setState] = useState<State>({
    initialised: false,
    isIphoneOrIpad: null,
    isSafari: null,
    iOSVersion: null,
    iOSManifestPath: null,
    androidVersion: null,
    androidApkPath: null,
  })
  const { isIphoneOrIpad, isSafari } = useDevice()

  const initialise = useCallback(
    async ({ platform }: { platform: "ios" | "android" }) => {
      let iosInfo:
        | {
            version: string
          }
        | undefined
      let androidMetaData:
        | {
            elements: { versionName: string }[]
          }
        | undefined

      if (platform === "ios") {
        const isoInfoFile = await fetch(iOSInfoFilePath)
        iosInfo = (await isoInfoFile.json()) || {}
      } else {
        const androidMetaDataFile = await fetch(androidMetaDataFilePath)
        androidMetaData = (await androidMetaDataFile.json()) || {}
      }

      setState((s) => ({
        ...s,
        initialised: true,
        iOSVersion: iosInfo?.version || "",
        iOSManifestPath,
        androidVersion: androidMetaData?.elements?.[0].versionName || "",
        androidApkPath,
      }))
    },
    [androidMetaDataFilePath, iOSInfoFilePath, iOSManifestPath, androidApkPath]
  )

  useEffect(() => {
    setState((s) => ({
      ...s,
      isSafari,
      isIphoneOrIpad,
    }))
  }, [isIphoneOrIpad, isSafari])

  useEffect(() => {
    if (state.isIphoneOrIpad !== null && state.isSafari !== null) {
      const platform = state.isIphoneOrIpad ? "ios" : "android"
      initialise({
        platform,
      })
    }
  }, [state, initialise])

  return (
    <div style={{ paddingBottom: 10 }}>
      {!state.initialised && <p>Loading...</p>}

      {/********************
       * IPHONE OR IPAD
       ********************/}

      {state.initialised && state.isIphoneOrIpad && !state.isSafari && (
        <div
          id="not-mobile-safari-warning"
          style={{
            paddingTop: 2,
            paddingRight: 20,
            paddingBottom: 2,
            paddingLeft: 20,
            backgroundColor: "orange",
            borderRadius: 13,
          }}
        >
          <p>
            <i className="bi-exclamation-triangle-fill"></i>&nbsp; Please open
            this page in safari to install the app on to your iPhone
          </p>
        </div>
      )}

      {state.initialised && state.isIphoneOrIpad && (
        <div id="ios-content">
          <p>Version: {state.iOSVersion || "none found"}</p>
          {!!state.iOSManifestPath && (
            <a
              className="button"
              href={`itms-services://?action=download-manifest&url=${encodeURIComponent(
                state.iOSManifestPath
              )}`}
            >
              <i className="bi-download"></i>&nbsp; Download
            </a>
          )}
          <div className="card" style={{ marginTop: 30 }}>
            <header>
              <h4>Register your device</h4>
            </header>
            <section>
              iOS users need to register their device before accessing the App
            </section>
            <section>
              The below link shows you how to get your device udid which you
              then need to email to us at: hello@72days.dev
            </section>
            <section>
              <a
                className="button"
                target="_blank"
                href={getUdidURL}
                rel="noreferrer"
              >
                <i className="bi-link"></i>&nbsp; Get Device UDID
              </a>
            </section>
            <section>
              Once we have registered your device we will send you a
              confirmation email after which you will be able to use the
              Download link above to install the app
            </section>
          </div>
        </div>
      )}

      {/********************
       * NOT IPHONE OR IPAD
       ********************/}

      {state.initialised && !state.isIphoneOrIpad && (
        <>
          <p>Latest version: {state.androidVersion || "none found"}</p>
          <div id="android-content">
            {state.androidApkPath && (
              <a
                id="android-download-link"
                className="button"
                href={state.androidApkPath}
              >
                <i className="bi-download"></i>&nbsp; Android Apk Download
              </a>
            )}
          </div>
        </>
      )}
    </div>
  )
}
