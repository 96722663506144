import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyAp0Ht1h-2I3825aiqptmhO-Dsw_VwqnqA",
  authDomain: "apps-407e3.firebaseapp.com",
  projectId: "apps-407e3",
  storageBucket: "apps-407e3.appspot.com",
  messagingSenderId: "411599560670",
  appId: "1:411599560670:web:207086099d490d4e16c74f",
  measurementId: "G-MNJM4G5RCT",
}

export let app: any

export function firebaseInit() {
  initializeApp(firebaseConfig)
}
