import { FC, useState, useEffect } from "react"

import { useAuth } from "../../hooks"

export const Auth: FC = ({ children }) => {
  const {
    authStatusKnown,
    authorised,
    signInErrorMsg,
    passwordResetStatusMsg,
    onLogin,
    onLogout,
    onSendPasswordResetEmail,
  } = useAuth()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    setPassword("")
  }, [authorised])

  if (!authStatusKnown) {
    return null
  }

  return (
    <div>
      {!authorised && (
        <>
          <input
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            placeholder="Email"
            type="email"
            style={{ width: 200, marginRight: 12 }}
          />
          <input
            value={password}
            onChange={(event) => {
              setPassword(event.target.value)
            }}
            placeholder="Password"
            type="password"
            style={{ width: 250, marginRight: 12 }}
          />
        </>
      )}
      <button
        onClick={
          authorised
            ? onLogout
            : () => {
                onLogin(email, password)
              }
        }
      >
        {authorised ? "Log Out" : "Log In"}
      </button>
      <p>{signInErrorMsg}</p>
      {!authorised && (
        <>
          <label htmlFor="modal_1" className="button warning">
            Reset Password
          </label>
          <div className="modal">
            <input id="modal_1" type="checkbox" />
            <label htmlFor="modal_1" className="overlay"></label>
            <article>
              <header>
                <h3>Reset Password</h3>
                <label htmlFor="modal_1" className="close">
                  &times;
                </label>
              </header>
              <section className="content">
                <input
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  placeholder="Email"
                  type="email"
                  style={{ width: 300 }}
                />
                {!!passwordResetStatusMsg && <p>{passwordResetStatusMsg}</p>}
              </section>
              <footer>
                <button
                  onClick={() => {
                    onSendPasswordResetEmail(email)
                  }}
                  style={{ marginRight: 100 }}
                >
                  Send Password Reset Email
                </button>
                <label htmlFor="modal_1" className="button dangerous">
                  Close
                </label>
              </footer>
            </article>
          </div>
        </>
      )}
      <div>{authorised && children}</div>
    </div>
  )
}
