import { useParams } from "react-router-dom"

import { Auth, VersionHistory } from "../"

export function ClientPortal() {
  const { clientId } = useParams()
  return (
    <div>
      {clientId === "remaker" ? (
        <Auth>
          <VersionHistory
            env="default"
            deploymentDirectory="remaker%2Fremaker-bat-gg965"
            title="Remaker"
            authType="firebase"
          />
        </Auth>
      ) : (
        <p>client not found</p>
      )}
    </div>
  )
}
