import { AppConfig } from "./app.types"

// TODO: confirm the 72Ds one of these still works
export const getUdidURL =
  "https://udid-72days.bitmoremedia.com?email=hello@72days.dev"
// "https://device.bitmoremedia.com?email=hello@72days.dev"

export const firebaseHostPath =
  "https://firebasestorage.googleapis.com/v0/b/apps-407e3.appspot.com/o/"

// note: the below constants are manually kept in sync with iOS fastlane file, deploy-internal script and apps project listing project
export const internal_deployment_directory_dev =
  "betterdrivingtheory-badger-de289"
export const internal_deployment_directory_beta =
  "betterdrivingtheory-dolphin-be365"
export const internal_deployment_directory_prod =
  "betterdrivingtheory-fox-pr179"

export const apps: AppConfig[] = [
  {
    title: "Better Driving (Dev)",
    env: "dev",
    deploymentDirectory: internal_deployment_directory_dev,
    authType: "firebase",
  },
  {
    title: "Better Driving (Beta)",
    env: "beta",
    deploymentDirectory: internal_deployment_directory_beta,
    authType: "firebase",
  },
  {
    title: "Better Driving (Prod)",
    env: "prod",
    deploymentDirectory: internal_deployment_directory_prod,
    authType: "firebase",
  },
]
