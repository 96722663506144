import { useEffect, useCallback, useRef, useState } from "react"
import {
  getAuth,
  onAuthStateChanged,
  User,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth"

export function useAuth() {
  const [authStatusKnown, setAuthStatusKnown] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const [signInErrorMsg, setSignInErrorMsg] = useState("")
  const [passwordResetStatusMsg, setPasswordResetStatusMsg] = useState("")
  const authListenerRef = useRef<any>()
  const passwordResetStatusTimerRef = useRef<number | undefined>()

  const startAuthListener = useCallback(() => {
    const auth = getAuth()
    onAuthStateChanged(auth, (thisUser) => {
      setAuthStatusKnown(true)
      setUser(thisUser)
    })
  }, [])

  const stopAuthListener = useCallback(() => {
    if (authListenerRef.current) {
      authListenerRef.current()
    }
  }, [])

  const onLogin = useCallback(async (email: string, password: string) => {
    const auth = getAuth()
    try {
      await signInWithEmailAndPassword(auth, email, password)
      setSignInErrorMsg("")
    } catch (error) {
      console.log(error)
      setSignInErrorMsg("Error Signing In")
    }
  }, [])

  const onLogout = useCallback(() => {
    const auth = getAuth()
    signOut(auth)
  }, [])

  const onSendPasswordResetEmail = useCallback((email: string) => {
    const auth = getAuth()
    try {
      if (email) {
        sendPasswordResetEmail(auth, email)
        passwordResetStatusTimerRef.current = window.setTimeout(() => {
          setPasswordResetStatusMsg(
            `If you have an account with us, a password reset email is now on it's way to you`
          )
          passwordResetStatusTimerRef.current = window.setTimeout(() => {
            setPasswordResetStatusMsg("")
          }, 3000)
        }, 600)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    startAuthListener()
    return () => {
      stopAuthListener()
      if (passwordResetStatusTimerRef.current) {
        window.clearTimeout(passwordResetStatusTimerRef.current)
      }
    }
  }, [startAuthListener, stopAuthListener])

  return {
    authStatusKnown,
    authorised: user !== null,
    signInErrorMsg,
    passwordResetStatusMsg,
    onLogin,
    onLogout,
    onSendPasswordResetEmail,
  }
}
