import { Routes, Route, Link } from "react-router-dom"
import { firebaseInit } from "./module/firebase"

import { VersionHistory, ClientPortal, Auth } from "./components"

import { apps } from "./constants"

firebaseInit()

function Home() {
  return (
    <>
      <p>
        Having issues accessing an app? Contact as at{" "}
        <a href="mailto:support@72days.dev">support@72days.dev</a>
      </p>
    </>
  )
}

function AppSelect() {
  return (
    <>
      {apps.map((appConfig) => {
        return (
          <div key={appConfig.title}>
            <a href={appConfig.deploymentDirectory}>{appConfig.title}</a>
            <br />
          </div>
        )
      })}
    </>
  )
}

function App() {
  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <div id="content">
        <Link to="/">
          <h1 style={{ color: "black" }}>72 Days Apps</h1>
        </Link>

        <Routes>
          <Route path="/" element={<Home />} />
          {apps.map((appConfig) => {
            return (
              <Route
                key={appConfig.title}
                path={`/${appConfig.deploymentDirectory}`}
                element={
                  <Auth>
                    <VersionHistory {...appConfig} />
                  </Auth>
                }
              />
            )
          })}
          <Route path="/just-the-right-amount" element={<AppSelect />} />
          <Route path="/clients/:clientId" element={<ClientPortal />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
